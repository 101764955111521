import { Box, IconButton, Stack, useTheme } from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { FaIcon } from '../icons';
import { Flex } from './flex';
import { Button } from '../buttons';
import { JSXChildren } from '@framework/src/libs/types';
import { Divider } from './divider';
import { DelayedLinearProgress } from '../../molecules/feedback';

export type ClosableDrawerProps = DrawerProps & {
  controls?: JSXChildren;
  loading?: boolean;
  onXCloseClick?: () => void;
};

export function ClosableDrawer({ sx, controls, loading = false, onXCloseClick, ...props }: ClosableDrawerProps) {
  const theme = useTheme();

  const handleCrossClick = () => {
    typeof onXCloseClick === 'function' ? onXCloseClick() : props.onClose && props?.onClose({}, 'backdropClick');
  };

  return (
    <Drawer anchor="right" {...props}>
      <DelayedLinearProgress show={loading} />
      <Stack
        sx={{
          height: '100%',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}
      >
        <Box sx={{ width: '100%', marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
          <IconButton
            onClick={handleCrossClick}
            sx={{ marginTop: theme.spacing(1), width: theme.spacing(1.8), height: theme.spacing(1.8) }}
          >
            <FaIcon name={'xmark-large'} width={theme.spacing(1.7)} height={theme.spacing(1.7)} />
          </IconButton>
        </Box>
        {/* theme.spacing(4) - is a summ of paddings for cross button container above ^ */}
        <Box flex={1} sx={(theme)=>({maxHeight: `calc(100% - ${theme.spacing(4)})`, overflow: 'auto'})}>{props.children}</Box>
        {controls && (
          <Box sx={{ width: '100%', paddingBottom: theme.spacing(1), paddingTop: theme.spacing(1) }}>
            <Divider />
            {controls}
          </Box>
        )}
      </Stack>
    </Drawer>
  );
}
