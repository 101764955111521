import { Scheduler } from './scheduler/Scheduler';
import { gridConfig, projectConfig, schedulerConfig } from './config';
import './scheduler/scheduler.scss';
import { Stack, Typography, Box, useTheme, IconButton } from '@mui/material';
import { FaIcon } from '@atoms/icons';
import {DateHelper} from '@bryntum/schedulerpro';
import { Button } from '@atoms/buttons';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { EditShiftDrawer } from './drawers/editShift';
import { useShiftAddEditStore } from '@libs/store/shifts';
import { FormattedMessage, useIntl } from 'react-intl';
import { Shift as SchedulerShiftModel } from './scheduler/lib/Shift';

export function ShiftsSchedulerPage() {
  const theme = useTheme();
  const {openShiftDrawer} = useShiftAddEditStore();

  const [dateRange, setDateRange] = useState({
    startDate: DateHelper.startOf(new Date(), 'week', undefined, 1),
    endDate: DateHelper.add(DateHelper.startOf(new Date(), 'week', undefined, 1), 7, 'days'),
  });

  const handleTodayClick = () => {
    setDateRange({
      startDate: DateHelper.startOf(new Date(), 'week', undefined, 1),
      endDate: DateHelper.add(DateHelper.startOf(new Date(), 'week', undefined, 1), 7, 'days'),
    });
  };
  const handleWeekBackClick = () => {
    setDateRange({
      startDate: DateHelper.add(dateRange.startDate, -7, 'days'),
      endDate: dateRange.startDate,
    });
  };
  const handleWeekForwardClick = () => {
    setDateRange({
      startDate: dateRange.endDate,
      endDate: DateHelper.add(dateRange.endDate, 7, 'days'),
    });
  };

  const handlePublishShift = () =>{}
  const handleNewShift = () =>{
    openShiftDrawer();
  }

  const handleShiftDetailsOpen = (shift: SchedulerShiftModel)=>{
    if(['Opened','Pending', 'Reopened', 'Unassigned'].includes(shift.status)){
      // open edit mode drawer
      shift.id && openShiftDrawer(shift.id+''); // edit mode
    }

    if(['Assigned','InProgress', 'Completed'].includes(shift.status)){
      // open "in progress" view drawer
    }
  }

  return (
    <>
      <Stack
        direction={'row'}
        spacing={2}
        sx={(theme) => ({
          width: '100%',
          paddingLeft: theme.spacing(1),
        })}
      >
        <Box
          sx={{
            paddingTop: '2px',
          }}
        >
          <FaIcon name="calendar-lines" fontSize={24} />
        </Box>
        <Typography variant="h5">{DateHelper.format(new Date(), 'MMMM YYYY')}</Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: 'white', color: theme.palette.text.primary }}
          onClick={handleTodayClick}
        >
          <FormattedMessage id="shifts.switchForTodayButton.label" />
        </Button>
        <IconButton aria-label="delete" size="small" onClick={handleWeekBackClick}>
          <ArrowBackIosNewIcon fontSize="inherit" />
        </IconButton>
        <IconButton aria-label="delete" size="small" onClick={handleWeekForwardClick}>
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
        <Button variant="text" startIcon={<AddIcon />}>
          <FormattedMessage id="shifts.addFilterButton.label" />
        </Button>
        <Box flex={1}/>
        <Button variant="outlined" onClick={handlePublishShift}>
          <FormattedMessage id="shifts.publishShiftButton.label" />
        </Button>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleNewShift}>
          <FormattedMessage id="shifts.newShiftButton.label" />
        </Button>
      </Stack>
      <Scheduler
        gridConfig={{
          ...gridConfig,
          onCellDblClick: (evt) => {
            handleShiftDetailsOpen(evt.record as SchedulerShiftModel);
          },
        }}
        projectConfig={projectConfig}
        schedulerConfig={{ ...schedulerConfig,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          onEventDblClick: (evt) =>{
            // evt.resourceRecord <- here - the nurse record could be get
            handleShiftDetailsOpen(evt.eventRecord as SchedulerShiftModel);
          }
        }}
      />
      <EditShiftDrawer/>
    </>
  );
}
