import { ComingSoon } from '@organisms/dataDisplay';
import { ShiftsSchedulerPage } from './shiftsScheduler';
import { FeatureFlags } from '@libs/analytics';
import { Scheduler } from './scheduler';
import '@bryntum/schedulerpro/schedulerpro.stockholm.css';

export function Component() {
    return <ShiftsSchedulerPage />;

}
