import { EventModel } from '@bryntum/schedulerpro';
import { Qualification } from '@libs/models/settings';

// Custom Shift model, based on EventModel with additional fields and changed defaults
export class Shift extends EventModel {
  declare patient: string;
  declare requiredRole: string;
  declare status:
    | 'Opened'
    | 'Reopened'
    | 'Assigned'
    | 'Unassigned'
    | 'Pending'
    | 'NoShow'
    | 'InProgress'
    | 'Completed'
    | 'Canceled';
  declare isShared: boolean;
  declare isUrgent: boolean;
  declare startTime: number;
  declare actualStartDate: number;
  declare qualifications: Qualification[];

  static fields = [
    'patient',
    'requiredRole',
    // override field defaultValue to hours
    { name: 'durationUnit', defaultValue: 'h' },
  ];

  static defaults = {
    // In this demo, default duration for sessions will be hours (instead of days)
    durationUnit: 'h',
  };
}
