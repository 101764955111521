import { FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRulesToDefaultMessage, getErrorMessage } from '@libs/forms';
import { TextField, TextFieldProps } from '@atoms/inputs';

type FormTextFieldProps<T extends FieldValues> = Omit<TextFieldProps, 'name'> & {
  name: Path<T>;
  requiredMessage?: boolean;
  options?: RegisterOptions<T, Path<T>>;
};
/**
 * Wrapper around `TextField` that integrates with react-hook-form.
 */
export function FormTextField<T extends FieldValues>({
  name,
  required,
  requiredMessage,
  options,
  ...props
}: FormTextFieldProps<T>) {
  const intl = useIntl();
  const {
    register,
    formState: { errors },
  } = useFormContext<T>();
  const error = errors[name];
  const parsedOptions = useRulesToDefaultMessage(options);

  return (
    <TextField
      {...props}
      required={required}
      error={!!error}
      helperText={getErrorMessage(error)}
      {...register(name, {
        ...parsedOptions,
        ...(required
          ? {
              required: requiredMessage ?? intl.formatMessage({ id: 'forms.fieldIsRequired' }),
            }
          : {}),
      })}
    />
  );
}
