export const data = {
  success: true,
  calendars: {
    rows: [
      {
        id: 'workweek',
        name: 'Work week',
        intervals: [
          {
            recurrentStartDate: 'on Sat',
            recurrentEndDate: 'on Mon',
            isWorking: false,
          },
        ],
      },
      {
        id: 'weekends',
        name: 'Weekends',
        unspecifiedTimeIsWorking: false,
        intervals: [
          {
            name: 'Weekend',
            recurrentStartDate: 'on Sat at 0:00',
            recurrentEndDate: 'on Mon at 0:00',
            isWorking: false,
          },
        ],
      },
      {
        id: 'seconddayhalf',
        name: 'Second day half',
        unspecifiedTimeIsWorking: false,
        intervals: [
          {
            name: 'Available',
            recurrentStartDate: 'every weekday at 13:00',
            recurrentEndDate: 'every weekday at 17:00',
            isWorking: true,
          },
        ],
      },
      {
        id: 'allday',
        name: 'All day',
        unspecifiedTimeIsWorking: false,
        intervals: [
          {
            name: 'Available',
            recurrentStartDate: 'every weekday at 01:00',
            recurrentEndDate: 'every weekday at 23:59',
            isWorking: true,
          },
        ],
      },
    ],
  },
  resources: {
    rows: [
      {
        id: 1,
        name: 'Jacob Washington',
        department: 'General Medicine',
        avatar: 'https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        calendar: 'allday',
        rating: 4.8,
        qualifications: [
          {
            id:'cna',
            category:'',
            name:'',
            abbreviation: 'CNA'
          }
        ],
      },
      {
        id: 2,
        name: 'David Strauss',
        department: 'Radiation oncology nurse',
        avatar: 'https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        calendar: 'allday',
        rating: 4.8,
        qualifications: [
          {
            id:'lpn',
            category:'',
            name:'',
            abbreviation: 'LPN'
          },
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      {
        id: 3,
        name: 'Henrik Ibsen',
        department: 'Nurse',
        avatar: 'https://images.pexels.com/photos/35065/homeless-man-color-poverty.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        calendar: 'allday',
        rating: 4.8,
        qualifications: [
          {
            id:'cna',
            category:'',
            name:'',
            abbreviation: 'CNA'
          },
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      {
        id: 4,
        name: 'Linda von Schmitt',
        department: 'General Medicine',
        avatar: 'https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=600',
        calendar: 'allday',
        rating: 4.8,
        qualifications: [
          {
            id:'cna',
            category:'',
            name:'',
            abbreviation: 'CNA'
          },
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      {
        id: 5,
        name: 'Barbara Streisand',
        department: 'General Medicine',
        avatar: 'https://images.pexels.com/photos/1130626/pexels-photo-1130626.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        calendar: 'allday',
        rating: 4.8,
        qualifications: [
          {
            id:'cna',
            category:'',
            name:'',
            abbreviation: 'CNA'
          },
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      {
        id: 6,
        name: 'Michael Wolf',
        department: 'Nurse',
        avatar: 'https://images.pexels.com/photos/1933873/pexels-photo-1933873.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        calendar: 'allday',
        rating: 4.8,
        qualifications: [
          {
            id:'lpn',
            category:'',
            name:'',
            abbreviation: 'LPN'
          },
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      {
        id: 7,
        name: 'Lee Wong',
        department: 'Nurse',
        avatar: 'https://images.pexels.com/photos/2681751/pexels-photo-2681751.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        calendar: 'allday',
        rating: 4.8,
        qualifications: [
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      {
        id: 8,
        name: 'Madison Vik',
        department: 'Nurse',
        avatar: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        calendar: 'allday',
        rating: 4.8,
        qualifications: [
          {
            id:'lpn',
            category:'',
            name:'',
            abbreviation: 'LPN'
          },
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
    ],
  },
  events: {
    rows: [
      {
        id: 1,
        name: 'Caregiver',
        patient: 'Sarah Larson',
        confirmed: true,
        duration: 1,
        eventColor: 'white',
        requiredRole: 'General Medicine',
        status: 'Pending',
        isShared: true,
        isUrgent: true,
        syntheticStartDate: '2024-12-10',
        actualStartDate: '2024-12-10T03:00:00.000Z',
        startDate: '2024-12-10T03:00:00.000Z',
        qualifications: [
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      {
        id: 2,
        name: 'CNA/Caregiver',
        patient: 'Dave Mathews',
        duration: 2,
        eventColor: 'white',
        requiredRole: 'Nurse',
        status: 'Pending',
        isShared: true,
        isUrgent: true,
        syntheticStartDate: '2024-12-10',
        actualStartDate: '2024-12-10T04:00:00.000Z',
        startDate: '2024-12-10T04:00:00.000Z',
        qualifications: [
          {
            id:'cna',
            category:'',
            name:'',
            abbreviation: 'CNA'
          },
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      {
        id: 3,
        name: 'LPN/LVN',
        patient: 'Kevin Bell',
        confirmed: true,
        duration: 3,
        eventColor: 'white',
        requiredRole: 'Nurse',
        status: 'Pending',
        isShared: true,
        isUrgent: true,
        syntheticStartDate: '2024-12-10',
        actualStartDate: '2024-12-10T05:00:00.000Z',
        startDate: '2024-12-10T05:00:00.000Z',
        qualifications: [
          {
            id:'lpn',
            category:'',
            name:'',
            abbreviation: 'LPN'
          },
        ],
      },
      {
        id: 4,
        name: 'CNA',
        patient: 'Doug Williams',
        confirmed: true,
        duration: 4,
        eventColor: 'white',
        requiredRole: 'Nurse',
        status: 'Pending',
        isShared: true,
        isUrgent: true,
        syntheticStartDate: '2024-12-10',
        actualStartDate: '2024-12-10T06:00:00.000Z',
        startDate: '2024-12-10T06:00:00.000Z',
        qualifications: [
          {
            id: 'cna',
            category:'',
            name:'',
            abbreviation: 'CNA'
          },
        ],
      },
      {
        id: 5,
        name: 'CNA',
        patient: 'Fred Dermot',
        confirmed: true,
        duration: 1,
        eventColor: 'white',
        requiredRole: 'General Medicine',
        status: 'Pending',
        isShared: true,
        isUrgent: true,
        syntheticStartDate: '2024-12-10',
        actualStartDate: '2024-12-10T07:00:00.000Z',
        startDate: '2024-12-10T07:00:00.000Z',
        qualifications: [
          {
            id: 'cna',
            category:'',
            name:'',
            abbreviation: 'CNA'
          },
        ],
      },
      {
        id: 6,
        name: 'Caregiver',
        patient: 'Peter Ball',
        confirmed: true,
        duration: 2,
        eventColor: 'white',
        requiredRole: 'Nurse',
        status: 'Pending',
        isShared: true,
        isUrgent: true,
        syntheticStartDate: '2024-12-10',
        actualStartDate: '2024-12-10T08:00:00.000Z',
        startDate: '2024-12-10T08:00:00.000Z',
        qualifications: [
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      // {
      //   id: 7,
      //   name: 'Vaccination',
      //   patient: 'Lara Kraft',
      //   confirmed: true,
      //   duration: 3,
      //   eventColor: 'white',
      //   requiredRole: 'Nurse',
      //   status: 'Pending',
      //   isShared: true,
      //   isUrgent: true,
      //   syntheticStartDate: '2024-12-10',
      //   actualStartDate: '2024-12-10T09:00:00.000Z',
      //   startDate: '2024-12-10T09:00:00.000Z',
      // },
      // {
      //   id: 8,
      //   name: 'Vaccination',
      //   patient: 'Randy Barth',
      //   confirmed: true,
      //   duration: 4,
      //   eventColor: 'white',
      //   requiredRole: 'Nurse',
      //   status: 'Pending',
      //   isShared: true,
      //   isUrgent: true,
      //   syntheticStartDate: '2024-12-10',
      //   actualStartDate: '2024-12-10T10:00:00.000Z',
      //   startDate: '2024-12-10T10:00:00.000Z',
      // },
      // {
      //   id: 9,
      //   name: 'Moderna #1',
      //   patient: 'Peter Douglas',
      //   duration: 1,
      //   eventColor: 'white',
      //   requiredRole: 'Nurse',
      //   status: 'Pending',
      //   isShared: true,
      //   isUrgent: true,
      //   syntheticStartDate: '2024-12-10',
      //   actualStartDate: '2024-12-10T11:00:00.000Z',
      //   startDate: '2024-12-10T11:00:00.000Z',
      // },
      // {
      //   id: 10,
      //   name: 'Pfizer #2',
      //   patient: 'Fanny White',
      //   confirmed: true,
      //   duration: 2,
      //   eventColor: 'white',
      //   requiredRole: 'Nurse',
      //   status: 'Pending',
      //   isShared: true,
      //   isUrgent: true,
      //   syntheticStartDate: '2024-12-10',
      //   actualStartDate: '2024-12-10T14:00:00.000Z',
      //   startDate: '2024-12-10T14:00:00.000Z',
      // },
      // {
      //   id: 11,
      //   name: 'Vaccination',
      //   patient: 'Gordon Anderson',
      //   duration: 3,
      //   eventColor: 'white',
      //   requiredRole: 'Nurse',
      //   status: 'Pending',
      //   isShared: true,
      //   isUrgent: true,
      //   syntheticStartDate: '2024-12-10',
      //   actualStartDate: '2024-12-10T16:00:00.000Z',
      //   startDate: '2024-12-10T16:00:00.000Z',
      // },
      {
        id: 12,
        name: 'Caregiver',
        patient: 'Frank Enstein',
        confirmed: true,
        duration: 4,
        eventColor: 'white',
        requiredRole: 'General Medicine',
        status: 'Pending',
        isShared: true,
        isUrgent: true,
        syntheticStartDate: '2024-12-11',
        actualStartDate: '2024-12-11T20:00:00.000Z',
        startDate: '2024-12-11T20:00:00.000Z',
        qualifications: [
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
      {
        id: 13,
        name: 'Caregiver',
        patient: 'Katie Person',
        confirmed: true,
        duration: 5,
        eventColor: 'white',
        requiredRole: 'Radiation oncology nurse',
        status: 'Pending',
        isShared: true,
        isUrgent: true,
        syntheticStartDate: '2024-12-11',
        actualStartDate: '2024-12-11T22:00:00.000Z',
        startDate: '2024-12-11T22:00:00.000Z',
        qualifications: [
          {
            id:'caregiver',
            category:'',
            name:'',
            abbreviation: 'Caregiver'
          },
        ],
      },
    ],
  },
  assignments: {
    rows: [],
  },
  dependencies: {
    rows: [],
  },
};
