import { useRef, useEffect, useState, useCallback } from 'react';
import {
  BryntumGrid,
  BryntumSchedulerPro,
  BryntumSplitter,
  BryntumSchedulerProProps,
  BryntumGridProps,
} from '@bryntum/schedulerpro-react';
import { SchedulerPro, Grid, SchedulerResourceModel, ProjectModel, ProjectModelConfig } from '@bryntum/schedulerpro';
import { Shift } from './lib/Shift';
import { Doctor } from './lib/Doctor';
import { Drag } from './lib/Drag';
import { GlobalStyles } from '@mui/material';

import './scheduler.scss';
import { PrimaryPaper } from '@atoms/layout';

type SchedulerProps = {
  projectConfig: ProjectModel | ProjectModelConfig;
  schedulerConfig: Omit<BryntumSchedulerProProps, 'project'>;
  gridConfig: BryntumGridProps;
};
export function Scheduler({ projectConfig, schedulerConfig, gridConfig }: SchedulerProps) {
  const gridRef = useRef<BryntumGrid>(null);
  const schedulerRef = useRef<BryntumSchedulerPro>(null);
  const dragRef = useRef<Drag>(null);

  const [grid, setGrid] = useState<Grid>();
  const [scheduler, setScheduler] = useState<SchedulerPro>();

  useEffect(() => {
    setScheduler(schedulerRef.current?.instance);
    setGrid(gridRef.current?.instance);
  }, [gridRef, schedulerRef]);

  useEffect(() => {
    if (!scheduler || !grid) {
      return;
    }
    const { project } = scheduler!;
    // Create a chained version of the event store as our store.
    // It will be filtered to only display events that lack of assignments.
    // Config for grouping requiredRole in ascending mode while webpage loads initially.
    const chainedStore = (grid!.store = project.eventStore.chain(
      (eventRecord: Shift) => !eventRecord.assignments.length,
      undefined,
      {
        groupers: [
          {
            field: 'requiredRole',
            ascending: true,
          },
        ],
      },
    ));

    // When assignments change, update our chained store to reflect the changes.
    project.assignmentStore.on({
      change: () => chainedStore.fillFromMaster(),
      thisObj: grid!,
    });

    (dragRef as Drag).current = new Drag({
      grid: grid!,
      schedule: scheduler!,
      constrain: false,
      outerElement: grid!.element,
    });
  }, [grid, scheduler]);

  // We need to destroy Drag instance because React 18 Strict mode
  // runs this component twice in development mode and Drag has no
  // UI so it is not destroyed automatically as grid and scheduler.
  useEffect(() => {
    return () => (dragRef as Drag).current?.destroy?.();
  }, [dragRef]);

  const onSchedulerSelectionChange = useCallback(() => {
    const selectedRecords = scheduler!.selectedRecords as SchedulerResourceModel[];
    const { calendarHighlight } = scheduler!.features;
    if (selectedRecords.length > 0) {
      calendarHighlight.highlightResourceCalendars(selectedRecords);
    } else {
      calendarHighlight.unhighlightCalendars();
    }
  }, [scheduler]);

  const onGridSelectionChange = useCallback(() => {
    const maxHoursSchedule = 10;
    const selectedRecords = grid!.selectedRecords as Shift[];
    const { calendarHighlight } = scheduler!.features;
    const requiredRoles: Record<string, number> = {};

    selectedRecords.forEach((appointment: Shift) => (requiredRoles[appointment.requiredRole as string] = 1));

    if (Object.keys(requiredRoles).length === 1) {
      const appointment = selectedRecords[0] as Shift;

      const availableTalents = scheduler!.resourceStore.query((talent: Doctor) => {
        const events = scheduler?.assignmentStore.query((evt: any) => talent.id == evt.resourceId);
        const scheduledTime = (events as Shift[])?.reduce((acc, elem) => acc + elem.effort, 0);

        // return talent.role === appointment.requiredRole || !appointment.requiredRole;
        const talentQuals = talent.qualifications.map((qual) => qual.id);
        const reqQuals = Array.from(
          new Set(
            selectedRecords.reduce((acc, shift) => {
              const shiftQuals = shift.qualifications.map((qual) => qual.id);
              acc.push(...shiftQuals);
              return acc;
            }, [] as string[]),
          ),
        );

        return reqQuals.every((qual) => talentQuals.includes(qual));
      }) as SchedulerResourceModel[];

      calendarHighlight.highlightResourceCalendars(availableTalents);
    } else {
      calendarHighlight.unhighlightCalendars();
    }
  }, [grid, scheduler]);

  return (
    <PrimaryPaper sx={{ height: '100%', minHeight: 0, mt: 2, p: 0 }}>
      <GlobalStyles
        styles={(theme) => ({
          '.b-container': {
            borderRadius: theme.spacing(2),
          },
          '.b-react-portal-container': {
            width: '100%',
            height: '100%'
          },
          '.unscheduledNameCell': {
            paddingLeft: 0,
            width: '100%',
          },
          '.b-resourceinfo-cell': {},
          '.b-sch-event': {
            backgroundColor: 'white',
            color: theme.palette.text.primary,
            border: 'none !important',
            margin: 0,
          },
          '.b-grid-header': {
            textTransform: 'none',
          },
          '.b-grid-cell.b-group-title': {
            textTransform: 'none',
          },
          '.b-grid-cell': {
            padding: '0',
          },
          '.b-sch-event:not(.b-milestone) ': {
            width: '100% !important',
            margin: '0 !important',
          },
          '.b-jsx-container': {
            width: '100%',
          },
          '.b-sch-event-content': {
            width: '100%',
            margin: '0 !important',
          },
          '.calendar-item-schedule-group-day': {
            backgroundColor: '#a9cad9',
          },
          '.calendar-item-schedule-group-eve': {
            backgroundColor: '#fdd835',
          },
          '.calendar-item-schedule-group-noc': {
            backgroundColor: '#abb9ff',
          },
          '.calendar-item-holder-badge': {
            width: '6px',
            height: '100%',
          },
          '.b-sch-nonworkingtime': {
            background: 'red !important',
          },
          '.calendar-item-holder-timingText':{
            width: '100px',
          }
        })}
      />
      <div id="content" className={'b-side-by-side'}>
        <div className="scheduler-container">
          <BryntumSchedulerPro
            ref={schedulerRef}
            cls="b-schedulerpro"
            {...schedulerConfig}
            project={projectConfig}
            onSelectionChange={onSchedulerSelectionChange}
          />
        </div>
        <BryntumSplitter />
        <div className="grid-container">
          <BryntumGrid ref={gridRef} cls="b-unplannedgrid" {...gridConfig} onSelectionChange={onGridSelectionChange} />
        </div>
      </div>
    </PrimaryPaper>
  );
}
