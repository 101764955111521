import { OrganizationDetails } from '@libs/models/settings';
import { request } from '../../request';

export function createOrganizationDetails(data: Omit<OrganizationDetails, 'id'>) {
  return request.post('/Agency/AgencyOnboarding', data);
}
export async function getOrganizationDetails(): Promise<OrganizationDetails> {
  const response = await request.get<OrganizationDetails>('/v2/settings/details');
  return response.data;
}

export function saveOrganizationDetails(data: OrganizationDetails) {
  return request.put('/v2/settings/details', data);
}
