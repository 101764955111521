import { Checkbox, CheckboxProps } from '@atoms/inputs';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

type FormCheckboxProps<T> = CheckboxProps & {
  name: Path<T>;
};
export function FormCheckbox<T extends FieldValues>({ name, ...props }: FormCheckboxProps<T>) {
  const { control, setValue } = useFormContext<T>();

  return (
    <Controller<T>
      name={name}
      control={control}
      render={({ field }) => (
        <Checkbox
          {...props}
          {...field}
          onChange={(e, checked) => {
            // @ts-expect-error - to use `boolean` instead Path<T>
            setValue(name, checked);
            props.onChange?.(e, checked);
          }}
        />
      )}
    />
  );
}
