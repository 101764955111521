import { Box, Flex, PrimaryPaper } from '@atoms/layout';
import { GlobalStyles, Stack, Typography, Chip, alpha } from '@mui/material';
import { Shift } from '../scheduler/lib/Shift';
import {
  DateHelper,
} from '@bryntum/schedulerpro';
import { HotShiftIcon } from './icons/hotIcon';
import { SharedShiftIcon } from './icons/sharedShift';

type CalendarItemProps = {
  data: Shift;
  mode: 'grid' | 'scheduler' | 'holder',
};

export function CalendarItem({ data, mode='grid' }: CalendarItemProps) {
  const timeHour = new Date(data.actualStartDate).getHours();
  const startTimeLabel = DateHelper.format(new Date(data.actualStartDate), 'h a');//`${data.startTime} ${data.startTime > 12 ? 'PM' : 'AM'}`;
  const durationLabel = `${data.duration}h`;
  let color = '#a9cad9';
  let badge = 'day';
  if (timeHour >= 6 && timeHour <= 12) {
    color = '#fdd835';
    badge ='eve';
  }
  if (timeHour > 12 && timeHour <= 21) {
    color = `#abb9ff`;
    badge = 'noc';
  }

  return (
    <Stack
      direction={'row'}
      sx={(theme) => ({
        width: '100%',
        height: mode ==='scheduler'?'54px':'60px',
        paddingLeft: 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
      })}
    >
      <Box
        className={`calendar-item-schedule-group-${badge} ${mode==='holder' && 'calendar-item-holder-badge'}`}
        sx={{
          width: '6px',
          height: '100%',
          backgroundColor: color,
          marginRight: '4px'
        }}
      />
      <Stack
        direction={'column'}
        flex={1}
        sx={(theme) => ({
          paddingTop: '4px',
          paddingBottom: '4px',
        })}
      >
        <Stack
          direction={'row'}
          sx={(theme) => ({ borderBottom: `1px solid ${alpha(theme.palette.divider, 0.03)}`, paddingBottom: '4px' })}
        >
          <Typography sx={(theme)=>({
            fontSize: mode==='scheduler' ? '12px' : '14px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            color: theme.palette.text.primary
          })}>{data.name}</Typography>
          <Box flex={1} />
          {mode !== 'holder' &&<Chip label={data.status} color="warning" size="small" />}
        </Stack>
        <Stack direction={'row'}>
          <Typography
            className={mode==='holder' ? 'calendar-item-holder-timingText': ''}
           sx={{fontSize: mode==='scheduler' ? '12px' : '14px'}}>{`${startTimeLabel}・${durationLabel}`}</Typography>
          <Box flex={1} />
          <Stack direction={'row'} spacing={1} sx={{marginTop:'2px'}}>
          {(data.isUrgent && mode!=='holder') && <Box sx={{ width:'16px', height:'16px'}}><HotShiftIcon/></Box>}
          {(data.isShared&& mode!=='holder') && <Box sx={{ width:'16px', height:'16px'}}><SharedShiftIcon/></Box>}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
