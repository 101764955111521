import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, ClosableDrawer, Divider, Flex, PrimaryPaper } from '@atoms/layout';
import { useDirectQualificationsStore, useOrgQualificationsStore, useOrgSpecialtiesStore } from '@libs/store/settings';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { Button } from '@atoms/buttons';
import { FaIcon } from '@atoms/icons';
import { DEPARTMENT_TYPES, DepartmentDetails, OrgDepartmentType, Qualification } from '@libs/models/settings';
import { Checkbox, Select, TextField } from '@atoms/inputs';
import { useOrgDepartmentsStore } from '@libs/store/settings/departments';
import { SkeletonProgress } from '@molecules/feedback';
import { useShiftAddEditStore } from '@libs/store/shifts';
import { Shift } from '@libs/models/shifts';
import { v4 as uuidV4 } from 'uuid';
import { DateHelper } from '@bryntum/schedulerpro';
import { useForm } from 'react-hook-form';
import { Form } from '@molecules/form/form';
import { FormCheckbox, FormDatePicker, FormRadioGroup, FormSelect, FormTextField } from '@molecules/form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type ShiftFormData = {
  talentType: number;
  assignType: number;
  shiftsQuantity: number;
  qualifications: string;
  assignees: string;
};

export function EditShiftDrawer() {
  const theme = useTheme();
  const intl = useIntl();

  const {
    drawerOpen,
    loading: shiftLoading,
    data: currentShiftDetails,
    shiftId,
    closeShiftDrawer,
    load,
    drawerMode,
  } = useShiftAddEditStore();

  const qualStore = useDirectQualificationsStore();
  const { load: loadDirectQualifications, loading: isDirectListLoading } = qualStore;
  const directQualifications = qualStore.data || [];

  const orgQualStore = useOrgQualificationsStore();
  const { orgQualifications, load: loadOrgQuals, loading: isOrgQualListLoading } = orgQualStore;

  const { loadSpecialtiesList: loadSpecialties, orgSpecialties } = useOrgSpecialtiesStore();

  const isAddDialog = drawerMode == 'add';
  const localizationSubset = isAddDialog ? 'shifts.addShiftDrawer' : 'shifts.editShiftDrawer';
  const open = drawerOpen;

  const loading = shiftLoading || isOrgQualListLoading;

  const initial: Shift = {
    id: '',
    internalId: `ABC-${DateHelper.format(new Date(), 'DDMMYYYY')}-${uuidV4().split('-')[0]}`,
    staffNurseQualificationFilters: [],
    staffOtherQualificationFilters: [],
    staff: {
      firstName:'',
      id:'',
      imageURL:'',
      lastName:'',
      nurseQualifications:[],
      specialties:[],
    },
    startDate: (new Date()).toISOString(),
    endDate: (new Date()).toISOString(),
    timeZone: '',
    department: {
      id: '',
      label: '',
    },
    subDepartment: {
      id: '',
      label: '',
    },
    speciality: {
      id: '',
      title: '',
      category: '',
    },
    tags: [],
    note: '',
    isUrgent: false,
    status: 'Opened',
  };

  const methods = useForm<ShiftFormData>({
    defaultValues: {
      talentType: 1,
      assignType: 1,
      shiftsQuantity: 1,
      qualifications: '',
      assignees: '',
    },
  });
  const { setFocus, setValue, getValues } = methods;

  const [currentShift, setCurrentShift] = useState<Shift>({
    ...initial,
    ...(currentShiftDetails || {}),
  });

  const [errors, setErrors] = useState<boolean>(false);

  useEffect(() => {
    if (shiftId) {
      load(shiftId);
    }
  }, [shiftId]);

  useEffect(() => {
    if (!orgQualifications) {
      loadOrgQuals();
    }
  }, [orgQualifications]);

  useEffect(() => {
    setCurrentShift({
      ...currentShift,
      ...currentShiftDetails,
    });
  }, [currentShiftDetails]);

  useEffect(() => {
    if (!directQualifications.length) {
      loadDirectQualifications();
    }
  }, [loadDirectQualifications]);

  const handleClose = () => {
    closeShiftDrawer();
  };

  useEffect(() => {
    setErrors(false);
  }, [currentShift]);

  const handleSave = async () => {
    // validation
    // const errors = !currentShift.label || !!currentShift.specialties.find((spec) => !spec.id);
    setErrors(errors);

    if (!errors) {
      // isAddDialog
      //   ? await createDepartment(currentDepartment)
      //   : await updateDepartment(currentDepartmentID, currentDepartment);
      // loadTree();
      handleClose();
    }
  };

  const handlePublish = async ()=>{

  }

  const handleCancel = handleClose;

  return (
    <ClosableDrawer
      open={open}
      loading={loading}
      onClose={handleClose}
      controls={
        <Stack direction={'row-reverse'} spacing={2} sx={(theme) => ({ paddingBottom: theme.spacing(1) })}>
          <MenuButton variant="contained" onClick={handlePublish} disabled={loading}>
            <FormattedMessage  id={`${localizationSubset}.menuButtons.publish.label`} />
          </MenuButton>
          <MenuButton
            variant='outlined'
            onClick={handleSave}
            disabled={loading}
          >
            <FormattedMessage id="common.save" />
          </MenuButton>
          <Box flex={1}/>
          <MenuButton
            onClick={handleCancel}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            disabled={loading}
          >
            <FormattedMessage id={`${localizationSubset}.menuButtons.back.label`} />
          </MenuButton>
        </Stack>
      }
    >
      <Stack direction={'column'} sx={{ height: '100%' }} spacing={2} width={{ xs: '300px', sm: '600px' }}>
        {/* Header */}
        <Stack direction={'row'}>
          <Typography variant="h5">
            <FormattedMessage id={`${localizationSubset}.dialogHeading`} />
          </Typography>
          <Box flex={1} />
          <Typography variant="caption" sx={{ marginTop: '6px' }}>
            <FormattedMessage
              id={`${localizationSubset}.internalIDPlaceholder`}
              values={{
                shiftId: currentShift.internalId,
              }}
            />
          </Typography>
        </Stack>
        <Form formUtils={methods} style={{ height: '100%' }}>
          {/* Shift configuration */}
          <Typography variant="subtitle1">
            <FormattedMessage id={`${localizationSubset}.shiftConfiguration.sectTitle`} />
          </Typography>
          <PrimaryPaper>
            <Stack direction={'row'}>
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.shiftTemplate.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.shiftConfiguration.shiftTemplate.placeholder`,
                })}
                fullWidth
                name="registration_date"
                options={[]}
                required
              />
              <FormTextField
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.quantity.label`} />}
                fullWidth
                name="shiftsQuantity"
                // options={[]}
              />

            </Stack>
            <FormCheckbox
                name="useOrganizationPhoneNumber"
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.markAsUrgent.label`} />}
                size="medium"
              />
          </PrimaryPaper>

          <Stack direction={'column'} spacing={2}>
            {/* Talent */}
            <Box>
              <Typography variant="subtitle1">
                <FormattedMessage id={`${localizationSubset}.talentConfiguration.sectTitle`} />
              </Typography>
              <PrimaryPaper>
                <FormRadioGroup
                  name="talentType"
                  label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.label`} />}
                  items={[
                    {
                      label: <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.all`} />,
                      value: 1,
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.internal`} />
                      ),
                      value: 2,
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.external`} />
                      ),
                      value: 3,
                    },
                  ]}
                  required
                  disabled={loading}
                  row
                />
                <FormRadioGroup
                  name="assignType"
                  label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.assignType.label`} />}
                  items={[
                    {
                      label: (
                        <FormattedMessage
                          id={`${localizationSubset}.talentConfiguration.assignType.items.individual`}
                        />
                      ),
                      value: 1,
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.assignType.items.team`} />
                      ),
                      value: 2,
                    },
                  ]}
                  required
                  disabled={loading}
                  row
                />
                <FormSelect
                  fullWidth
                  label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.qualifications.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.talentConfiguration.qualifications.placeholder`,
                  })}
                  name="qualifications"
                  required
                  // multiple
                  options={[...(orgQualifications?.direct || []), ...(orgQualifications?.other || [])].map((qual) => ({
                    value: qual.id,
                    label: `(${qual.abbreviation}) ${qual.name}`,
                    key: qual.id,
                  }))}
                  // onChange={(e) => setValue(e.target.value, 'qualifications')}
                />
                <FormSelect
                  fullWidth
                  label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.assignees.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.talentConfiguration.assignees.placeholder`,
                  })}
                  name="assignees"
                  // multiple
                  options={[...(orgQualifications?.direct || []), ...(orgQualifications?.other || [])].map((qual) => ({
                    value: qual.id,
                    label: `(${qual.abbreviation}) ${qual.name}`,
                    key: qual.id,
                  }))}
                  // onChange={(e) => setValue(e.target.value, 'qualifications')}
                />
              </PrimaryPaper>
            </Box>
            {/* Date and duration */}
            <Box>
              <Typography variant="subtitle1">
                <FormattedMessage id={`${localizationSubset}.dateAndDuration.sectTitle`} />
              </Typography>
              <PrimaryPaper>
                <Stack direction={'column'} spacing={1}>
                  <Stack direction={'row'}>
                    <FormDatePicker
                      disabled={loading}
                      label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.startDate.label`} />}
                      fullWidth
                      name="registration_date"
                      defaultValue={null}
                      required
                    />
                    <FormSelect
                      disabled={loading}
                      label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.shiftTypePreset.label`} />}
                      placeholder={intl.formatMessage({
                        id: `${localizationSubset}.dateAndDuration.shiftTypePreset.placeholder`,
                      })}
                      fullWidth
                      name="registration_date"
                      options={[]}
                    />
                  </Stack>
                  <Stack direction={'row'}>
                    <FormDatePicker
                      disabled={loading}
                      label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.shiftTypePreset.label`} />}
                      fullWidth
                      name="registration_date"
                      defaultValue={null}
                      required
                    />
                    <FormDatePicker
                      disabled={loading}
                      label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.endTime.label`} />}
                      fullWidth
                      name="registration_date"
                      defaultValue={null}
                      required
                    />
                  </Stack>
                  <Box>
                    <Typography variant="subtitle2">
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.duration.sectTitle`} />
                    </Typography>
                    <Chip label="start and end time not selected" disabled />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.timePreset.sectTitle`} />
                    </Typography>
                    <FormCheckbox
                      name="useOrganizationPhoneNumber"
                      label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.timePreset.checkboxLabel`} />}
                      size="medium"
                    />
                  </Box>
                </Stack>
              </PrimaryPaper>
            </Box>
            {/* Location */}
            <Box>
              <Typography variant="subtitle1">
                <FormattedMessage id={`${localizationSubset}.location.sectTitle`} />
              </Typography>
              <PrimaryPaper>
                <Stack direction={'row'}>
                  <FormSelect
                    disabled={loading}
                    label={<FormattedMessage id={`${localizationSubset}.location.locationName.label`} />}
                    placeholder={intl.formatMessage({
                      id: `${localizationSubset}.location.locationName.placeholder`,
                    })}
                    fullWidth
                    name="registration_date"
                    options={[]}
                    required
                  />
                  <FormSelect
                    disabled={loading}
                    label={<FormattedMessage id={`${localizationSubset}.location.department.label`} />}
                    placeholder={intl.formatMessage({
                      id: `${localizationSubset}.location.department.placeholder`,
                    })}
                    fullWidth
                    name="registration_date"
                    options={[]}
                  />
                </Stack>
                <Stack direction={'row'}>
                  <FormSelect
                    disabled={loading}
                    label={<FormattedMessage id={`${localizationSubset}.location.subDepartment.label`} />}
                    placeholder={intl.formatMessage({
                      id: `${localizationSubset}.location.subDepartment.placeholder`,
                    })}
                    fullWidth
                    name="registration_date"
                    options={[]}
                  />
                  <FormSelect
                    disabled={loading}
                    label={<FormattedMessage id={`${localizationSubset}.location.specialty.label`} />}
                    placeholder={intl.formatMessage({
                      id: `${localizationSubset}.location.specialty.placeholder`,
                    })}
                    fullWidth
                    name="registration_date"
                    options={[]}
                  />
                </Stack>
              </PrimaryPaper>
            </Box>
            {/* Additional info */}
            <Box>
              <Typography variant="subtitle1">
                <FormattedMessage id={`${localizationSubset}.additionalInfo.sectTitle`} />
              </Typography>
              <Stack direction={'column'} spacing={1}>
                <PrimaryPaper>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormSelect
                        disabled={loading}
                        label={<FormattedMessage id={`${localizationSubset}.additionalInfo.tag.label`} />}
                        placeholder={intl.formatMessage({
                          id: `${localizationSubset}.additionalInfo.tag.placeholder`,
                        })}
                        fullWidth
                        name="registration_date"
                        options={[]}
                      />
                    </Grid>
                  </Grid>
                </PrimaryPaper>
                <PrimaryPaper>
                  <Accordion sx={{ background: 'transparent' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                      <FormattedMessage id={`${localizationSubset}.additionalInfo.notes.sectTitle`} />
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormTextField
                        name="notes"
                        fullWidth
                        InputProps={{
                          sx: { height: '150px' },
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </PrimaryPaper>
              </Stack>
            </Box>
          </Stack>
        </Form>
      </Stack>
    </ClosableDrawer>
  );
}

const MenuButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(16.75),
}));
