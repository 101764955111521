import { alpha } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';

export const ScrollbarStyles: React.FC = () => {
  return (
    <GlobalStyles
      styles={(theme) => ({
        '::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          borderRadius: '3px',
        },
        '::-webkit-scrollbar-track': {
          width: '7px',
          height: '7px',
          borderRadius: '3px',
          background: alpha(theme.palette.common.white, 0.1),
          padding: '10px',
        },
        '::-webkit-scrollbar-thumb': {
          background: alpha(theme.palette.common.black, 0.2),
          borderRadius: '3px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: alpha(theme.palette.common.black, 0.4),
        }
      })}
    />
  );
};
