import { Box, Flex, PrimaryPaper } from '@atoms/layout';
import { GlobalStyles, Stack, Typography, Chip, alpha, Avatar } from '@mui/material';
import { Doctor } from '../scheduler/lib/Doctor';
import { FaIcon } from '@atoms/icons';

type CalendarItemProps = {
  data: Doctor;
};

export function ResourceItem({ data }: CalendarItemProps) {
  return (
    <Stack justifyContent={'center'}>
      <Stack
        direction={'row'}
        spacing={1}
        sx={(theme) => ({
          width: '100%',
          height: '100%',
          paddingLeft: '8px',
          paddingTop: '8px',
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Avatar src={data.avatar} sx={{ top: '8px', width: '32px', height: '32px' }} />
        <Stack
          direction={'column'}
          flex={1}
          sx={(theme) => ({
          })}
        >
          <Stack
            direction={'row'}
            sx={(theme) => ({
              paddingBottom: '4px',
            })}
          >
            <Typography
              sx={(theme) => ({
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 'bolder',
                letterSpacing: '0.1px',
                color: theme.palette.text.primary,
              })}
            >
              {data.name}
            </Typography>
            <Stack direction={'row'} sx={{ marginTop: '1px', marginLeft: '4px' }}>
              <FaIcon name="star" variant="solid" color={'#ffb400'} width={16} height={16} />
              <Typography
                sx={(theme) => ({
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                })}
              >
                {data.rating}
              </Typography>
            </Stack>
            <Box flex={1} />
          </Stack>

          <Stack direction={'row'} sx={(theme) => ({})}>
            {data.qualifications.map((qual) => (
              <Chip label={qual.abbreviation} size="small" sx={{ fontWeight: 500 }} />
            ))}
            <Box flex={1} />
            <Stack direction={'row'} sx={{ marginTop: '4px', marginLeft: '4px', marginRight: '4px' }} spacing={0.5}>
              <FaIcon name="clock" variant="solid" width={16} height={16} />
              <Typography
                sx={(theme) => ({
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                })}
              >
                {0} / {0}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
