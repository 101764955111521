import { createBrowserRouter, Navigate, Outlet, RouterProvider, useRouteError } from 'react-router-dom';
import { Flex } from '@atoms/layout';
import { AuthGuard } from '@molecules/auth/authGuard';
import { ErrorMessage } from '@organisms/dataDisplay';
import { ComingSoon } from '@organisms/dataDisplay/comingSoon';
import { MainLayout } from '@templates/mainLayout';
import { Component as SchedulePage} from '@pages/schedule';
export function Routing() {
  return <RouterProvider router={router} />;
}

const router = createBrowserRouter([
  {
    path: '/login',
    async lazy() {
      const { SignInPage } = await import('@pages/auth');
      return { Component: SignInPage };
    },
  },
  {
    path: '/otp',
    async lazy() {
      const { OtpPage } = await import('@pages/auth');
      return { Component: OtpPage };
    },
  },
  {
    path: '/register',
    element: <Outlet />,
    children: [
      {
        path: '/register',
        async lazy() {
          const { AdminSignUp } = await import('@pages/auth');
          return { Component: AdminSignUp };
        },
      },
      {
        path: '/register/details',
        async lazy() {
          const { AdminSignUpDetails } = await import('@pages/auth');
          return { Component: AdminSignUpDetails };
        },
      },
      {
        path: '/register/address',
        async lazy() {
          const { AdminSignUpAddress } = await import('@pages/auth');
          return { Component: AdminSignUpAddress };
        },
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout>
          <Outlet />
        </MainLayout>
      </AuthGuard>
    ),
    // TODO: make 404 page
    ErrorBoundary: () => {
      const error = useRouteError();
      console.error('ERROR', error);
      console.log('ERROR', {err:error});
      return (
        <>
          <MainLayout>
            <ErrorMessage error={error as Error} />
          </MainLayout>
        </>
      );
    },
    children: [
      {
        path: '/',
        lazy: () => import('@pages/dashboard'),
      },
      {
        path: '/schedule',
        // lazy: () => import('@pages/schedule'),
        element: <SchedulePage/>
      },
      {
        path: '/providers',
        lazy: () => import('@pages/talents'),
      },
      {
        path: '/settings',
        async lazy() {
          const { SettingsRoot } = await import('@pages/settings');
          return { Component: SettingsRoot };
        },
        children: [
          {
            path: '/settings/general',
            element: <Navigate to="/settings/organization" replace={true} />,
          },
          {
            path: '/settings/organization',
            async lazy() {
              const { OrganizationSettings } = await import('@pages/settings');
              return { Component: OrganizationSettings };
            },
            children: [
              {
                path: '/settings/organization/details',
                async lazy() {
                  const { OrganizationDetails } = await import('@pages/settings');
                  return { Component: OrganizationDetails };
                },
              },
            ],
          },
          {
            path: '/settings/qualifications-specialties',
            async lazy() {
              const { QualificationSpecialtiesDummy } = await import('@pages/settings');
              return { Component: QualificationSpecialtiesDummy };
            },
            children: [
              {
                path: '/settings/qualifications-specialties/qualifications',
                async lazy() {
                  const { Qualifications } = await import('@pages/settings');
                  return { Component: Qualifications };
                },
              },
              {
                path: '/settings/qualifications-specialties/specialties',
                async lazy() {
                  const { Specialties } = await import('@pages/settings');
                  return { Component: Specialties };
                },
              },
            ],
          },
          {
            path: '/settings/departments',
            async lazy() {
              const { Departments } = await import('@pages/settings');
              return { Component: Departments };
            },
          },
          {
            path: '/settings/locations',
            async lazy() {
              const { Locations } = await import('@pages/settings');
              return { Component: Locations };
            },
          },
          {
            path: '/settings/locations/:id',
            async lazy() {
              const { EditLocation } = await import('@pages/settings');
              return { Component: EditLocation };
            },
          },
          {
            path: '/settings/locations/new',
            async lazy() {
              const { NewLocation } = await import('@pages/settings');
              return { Component: NewLocation };
            },
          },
          {
            path: '/settings/*',
            element: (
              <Flex justifyCenter alignCenter height="100%" width="100%">
                <ComingSoon />
              </Flex>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/*',
    element: (
      <MainLayout>
        <ComingSoon />
      </MainLayout>
    ),
  },
]);
