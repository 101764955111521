import { RadioGroup, RadioGroupProps } from '@atoms/inputs';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

type FormRadioGroupProps<T> = Omit<RadioGroupProps, 'ref'> & {
  name: Path<T>;
};
export function FormRadioGroup<T extends FieldValues>({ name, ...props }: FormRadioGroupProps<T>) {
  const { control } = useFormContext<T>();

  return <Controller<T> name={name} control={control} render={({ field }) => <RadioGroup {...props} {...field} />} />;
}
