import {
  CalendarModel,
  Column,
  DateHelper,
  ProjectModelConfig,
  ResourceModel,
  StringHelper,
  PresetManager,
} from '@bryntum/schedulerpro';
import { BryntumGridProps, BryntumSchedulerProProps } from '@bryntum/schedulerpro-react';
import { Shift } from './scheduler/lib/Shift';
import { Doctor } from './scheduler/lib/Doctor';
import { data } from './data';
import { CalendarItem } from './components/calendarItem';
import { ResourceItem } from './components/resourceItem';

export const projectConfig: ProjectModelConfig = {
  resources: data.resources.rows,
  resourceStore: {
    modelClass: Doctor,
    sorters: [{ field: 'name', ascending: true }],
  },

  events: data.events.rows,
  eventStore: {
    // Unassigned events should remain in store
    removeUnassignedEvent: false,
    modelClass: Shift,
  },

  calendars: data.calendars.rows,
  calendar: 'dayAndWeek',

  assignments: [],
  dependencies: [],
};

export const schedulerConfig: BryntumSchedulerProProps = {
  // startDate: DateHelper.startOf(new Date(), 'week', undefined, 1),
  // endDate: new Date(2024, 12, 30),
  rowHeight: 64,
  barMargin: 10,
  tickSize: 250,
  fillTicks: true,
  zoomOnTimeAxisDoubleClick: false,
  eventStyle: 'border',
  eventColor: 'blue',
  allowOverlap: true,
  useInitialAnimation: false,
  resourceImagePath: './users',
  createEventOnDblClick: false,

  columns: [
    {
      type: 'template',
      field: 'name',
      text: 'Talents',
      width: 220,
      // showEventCount: false,
      // showMeta: (resourceRecord) => {
      //   const doctor = resourceRecord as Doctor;
      //   return `<i class="${doctor.roleIconCls}"></i>${doctor.role}`;
      // },
      template: (props) => {
        const { record } = props;
        // console.log('render telant', record?.project?.assignments);
        return <ResourceItem data={record as Doctor} />;
      },
    },
  ],

  // Custom view preset with header configuration
  // viewPreset: {
  //   base: 'dayAndWeek',
  //   id: 'myDayAndWeek',
  //   defaultSpan: 7,
  //   mainUnit: 'day',
  //   shiftUnit: 'week',
  //   shiftIncrement: 1,
  //   columnLinesFor: 1,
  //   // shiftUnit: 'week',
  //   // timeResolution: {
  //   //   // Dates will be snapped to this resolution
  //   //   unit: 'week', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
  //   //   increment: 1,
  //   // },
  //   headers: [
  //     {
  //       unit: 'M',
  //       align: 'center',
  //       dateFormat: 'MMMM Y',
  //     },
  //     {
  //       unit: 'D',
  //       align: 'center',
  //       dateFormat: 'ddd DD',
  //     },
  //   ],
  // },

  presets: [
    {
      base: 'dayAndWeek',
      id: 'myDayAndWeek',
    },
    {
      base: 'weekAndMonth',
    },
    {
      base: 'monthAndYear',
    },
    {
      base: 'year',
    },
  ],

  stickyEventsFeature: {
    disabled: true,
  },
  dependenciesFeature: {
    disabled: true,
  },
  eventResizeFeature: {
    disabled: true,
  },
  eventTooltipFeature: {
    disabled: true,
    // template: data =>``
  },
  timeRanges: [
    {
      id: 1,
      cls: 'todayColumnHighlight',
      name: DateHelper.format(new Date(), 'ddd DD'),
      startDate: DateHelper.startOf(new Date(), 'day'),
      duration: 1,
      durationUnit: 'day',
      style: 'background: #ecf2fc',
    },
    {
      id: 2,
      cls: 'todayColumnHighlight',
      name: DateHelper.format(new Date(), 'ddd DD'),
      startDate: 'on Mon at 0:00',
      duration: 1,
      durationUnit: 'day',
      style: 'background: #ecf2fc',
    },
  ],

  timeRangesFeature: {
    showHeaderElements: true,
    // showCurrentTimeLine: true,
  },
  groupFeature: {
    field: 'department',
    disabled: false,
    headerHeight: 48,
    renderer({ groupRowFor, column }: { groupRowFor: string; column: Column }) {
      if (column.parentIndex === 0) {
        return `${groupRowFor}`;
      }
      return '';
    },
  },
  stripeFeature: true,
  columnLinesFeature: true,
  filterBarFeature: {
    compactMode: true,
  },
  calendarHighlightFeature: {
    calendar: 'resource',
    // This method is provided to determine which resources are available for one or more eventRecords,
    // in order to highlight the right availability intervals
    collectAvailableResources({ scheduler, eventRecords }) {
      const shift = eventRecords[0] as Shift;
      return scheduler.resourceStore.query((talent: Doctor) => {
        const talentQuals = talent.qualifications.map((qual) => qual.id);
        const reqQuals = shift.qualifications.map((qual) => qual.id);
        return reqQuals.every((qual) => talentQuals.includes(qual));
        // return talent.role === shift.requiredRole || !shift.requiredRole},
      }) as ResourceModel[];
    },
  },
  // Configure event menu items with correct phrases (could also be done through localization)
  eventMenuFeature: {
    disabled: true,
    items: {
      deleteEvent: {
        text: 'Delete appointment',
      },
      unassignEvent: {
        text: 'Unschedule appointment',
      },
    },
  },
  overlappingEventSorter: (a, b) => {
    return new Date((a as Shift).actualStartDate).getTime() - new Date((b as Shift).actualStartDate).getTime();
  },

  eventEditFeature:{
    disabled: true,
  },
  cellEditFeature:{
    disabled: true,
  },
  eventDragFeature: {
    // validatorFn(params) {
    //   console.log('Validation', params);
    //   const { eventRecords, newResource, startDate, endDate } = params;
    //   const task = eventRecords[0] as Shift;
    //   const doctor = newResource as Doctor;
    //   const { calendar } = doctor;
    //   const valid =
    //       doctor.role === task.requiredRole &&
    //       (!calendar || (calendar as CalendarModel).isWorkingTime(startDate, endDate)),
    //     message = valid ? '' : 'No available slot';
    //   return {
    //     valid,
    //     message: (valid ? '' : '<i class="b-icon b-fa-exclamation-triangle"></i>') + message,
    //   };
    // },
  },
  taskEditFeature: {
    disabled: true,
    editorConfig: {
      title: 'Shift',
    },

    // Customize its contents inside the General tab
    items: {
      generalTab: {
        // Add a patient field
        items: {
          // Add a patient field
          orderField: {
            type: 'text',
            name: 'patient',
            label: 'Patient',
            // Place after name field
            weight: 150,
          },
        },
      },
    },
  },

  eventRenderer({ eventRecord }) {
    return <CalendarItem data={eventRecord as Shift} mode="scheduler" />;
  },
};

// Custom grid that holds unassigned appointments
export const gridConfig: BryntumGridProps = {
  selectionMode: {
    cell: false,
    // multiSelect : false,
  },
  stripeFeature: true,
  sortFeature: 'startDate',
  filterFeature: true,
  filterBarFeature: {
    compactMode: true,
  },
  groupFeature: {
    field: 'syntheticStartDate',

    headerHeight: 48,
    renderer({ groupRowFor, column }: { groupRowFor: Date; column: Column }) {
      if (column.parentIndex === 0) {
        return `${DateHelper.format(new Date(groupRowFor), 'MMM D, YYYY')}`;
      }
      return '';
    },
  },
  cellEditFeature: {
    disabled: true,
  },
  columns: [
    {
      type: 'template',
      field: 'name',
      text: 'Open Shifts',
      flex: 1,
      autoHeight: true,
      cellCls: 'unscheduledNameCell',
      template: ({ record }) => {
        return <CalendarItem data={record as Shift} mode="grid" />;
      },
    },
  ],
  disableGridRowModelWarning: true,
};
