import { create } from 'zustand';
import { getOrganizationDetails, saveOrganizationDetails } from '@libs/api/settings';
import { OrganizationDetails } from '@libs/models/settings';

type InitialState = {
  loading: boolean;
  data?: OrganizationDetails;
  save: (settings: OrganizationDetails) => Promise<void>;
  load: () => void;
};

export const useOrganizationDetailsStore = create<InitialState>()((set) => ({
  loading: false,
  save: async (settings) => {
    set({ loading: true });
    if (!settings) return;
    try {
      await saveOrganizationDetails(settings);
    } finally {
      set({ loading: false });
    }
  },
  load: async () => {
    set({ loading: true });
    try {
      const details = await getOrganizationDetails();
      set({ loading: false, data: details });
    } finally {
      set({ loading: false });
    }
  },
}));
