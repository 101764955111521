import {
  DomHelper,
  StringHelper,
  DragHelper,
  DateHelper,
  ScrollManager,
  SchedulerPro,
  Grid,
  Store,
  DragHelperConfig,
} from '@bryntum/schedulerpro';
import { Shift } from './Shift';
import ReactDOMServer from 'react-dom/server';
import { CalendarItem } from '@pages/schedule/components/calendarItem';

type DragConfig = DragHelperConfig & {
  grid: Grid;
  schedule: SchedulerPro;
  constrain: boolean;
  outerElement: HTMLElement;
};

// Handles dragging unscheduled appointment from the grid onto the schedule
export class Drag extends DragHelper {
  static defaultConfig = {
    callOnFunctions: true,
    autoSizeClonedTarget: false,
    unifiedProxy: true,

    // Prevent removing proxy on drop, we adopt it for usage in the Schedule
    removeProxyAfterDrop: false,

    // Don't drag the actual row element, clone it
    cloneTarget: true,
    // Only allow drops on the schedule area
    dropTargetSelector: '.b-timeline-subgrid',
    // Only allow drag of row elements inside on the unplanned grid
    targetSelector: '.b-grid-row:not(.b-group-row)',
  };

  public scrollManager!: ScrollManager;
  public grid: Grid;
  public outerElement: HTMLElement;
  public schedule!: SchedulerPro;
  public constrain: boolean;
  private context: any;
  current!: Drag;

  constructor(config: DragConfig) {
    super(config);
    this.grid = config.grid;
    this.schedule = config.schedule;
    this.constrain = config.constrain;
    this.outerElement = config.outerElement;
    this.scrollManager = config.schedule.scrollManager as ScrollManager;
  }

  override createProxy = (grabbedElement: HTMLElement): HTMLDivElement => {
    const { context, schedule, grid } = this;
    const appointment = grid.getRecordFromElement(grabbedElement) as Shift;
    const durationInPixels = schedule.timeAxisViewModel.getDistanceForDuration(appointment.durationMS);
    const proxy = document.createElement('div');

    proxy.style.cssText = 'background:rgba(0,0,0,0.1); border: 1px solid rgba(0,0,0,0.2)';
    proxy.style.width = `200px`;
    proxy.style.height = '54px';

    // Fake an event bar
    proxy.classList.add('b-sch-event-wrap', 'b-sch-style-border', 'b-unassigned-class', 'b-sch-horizontal');
    proxy.innerHTML = grabbedElement.innerHTML;
    proxy.innerHTML = ReactDOMServer.renderToStaticMarkup(<CalendarItem data={appointment as Shift} mode="holder" />);
    let totalDuration = 0;
    (grid.selectedRecords as Shift[]).forEach((appointment) => (totalDuration += appointment.duration));
    context.totalDuration = totalDuration;
    return proxy;
  };

  override onDragStart = ({ context }: { context: any }): void => {
    const { schedule, grid } = this;
    const { selectedRecords, store } = grid as Grid;
    const appointment = grid.getRecordFromElement(context.grabbed);

    // save a reference to the appointments being dragged so we can access them later
    context.appointments = selectedRecords.slice();
    context.relatedElements = selectedRecords
      .sort((r1, r2) => (store as Store).indexOf(r1) - (store as Store).indexOf(r2))
      .map((rec) => rec !== appointment && grid.getRowFor(rec).element)
      .filter((el) => el);
    schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid);
    // Prevent tooltips from showing while dragging
    schedule.features.eventTooltip.disabled = true;
  };

  override onDrag = ({ context }: { context: any }): void => {
    const { schedule } = this;
    const { appointments, totalDuration } = context;
    const requiredRole = appointments[0].requiredRole;
    const newStartDate = schedule.getDateFromCoordinate(context.newX, 'round', false);
    const lastShiftEndDate = newStartDate && DateHelper.add(newStartDate, totalDuration, appointments[0].durationUnit);
    const doctor = context.target && schedule.resolveResourceRecord(context.target);
    const calendar = doctor?.calendar;

    if (!calendar) {
      return;
    }

    // Only allow drops on the timeaxis
    context.valid =
      newStartDate &&
      // Require a resource with matching role
      (!requiredRole || doctor.role === requiredRole) &&
      // Ensure we don't break allowOverlap config
      (schedule.allowOverlap ||
        (schedule.isDateRangeAvailable(newStartDate, lastShiftEndDate, null, doctor) &&
          // Respect resource's working time
          (!calendar || calendar.isWorkingTime(newStartDate, lastShiftEndDate, true))));
    context.valid =true;

    // Save reference to the doctor so we can use it in onShiftDrop
    context.doctor = doctor;
  };

  // Drop callback after a mouse up, take action and transfer the unplanned appointment to the real EventStore (if it's valid)
  override onDrop = async ({ context }: { context: any }) => {
    const { schedule } = this;

    // If drop was done in a valid location, set the startDate and transfer the task to the Scheduler event store
    if (context.valid) {
      const { appointments, element, relatedElements = [], doctor } = context,
        coordinate = DomHelper.getTranslateX(element),
        dropDate = schedule.getDateFromCoordinate(coordinate, 'round', false),
        eventBarEls = [element, ...relatedElements];

      for (let i = 0; i < appointments.length; i++) {
        // We hand over the data + existing element to the Scheduler so it do the scheduling
        // await is used so that we have a reliable end date in the case of multiple event drag
        await schedule.scheduleEvent({
          eventRecord: appointments[i],
          // When dragging multiple appointments, add them back to back + assign to resource
          // startDate: new Date( appointments[i].startDate), //i === 0 ? dropDate : appointments[i - 1].endDate,
          startDate: i === 0 ? dropDate : appointments[i - 1].endDate,
          // Assign to the doctor (resource) it was dropped on
          resourceRecord: doctor,
          element: eventBarEls[i],
        });
      }
    }

    schedule.disableScrollingCloseToEdges(schedule.timeAxisSubGrid);
    schedule.features.eventTooltip.disabled = false;
  };
}
