export const HotShiftIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = 'none' }) => {
  return (
    <>
      <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.12871 4.16328C2.68417 3.19532 3.39199 2.30469 4.03183 1.68125C4.47011 2.11953 4.99042 2.7875 5.44042 3.36172C5.52949 3.47422 5.61386 3.58438 5.69589 3.6875C5.8037 3.82344 5.97011 3.90313 6.14355 3.90078C6.31699 3.89844 6.48105 3.81641 6.58652 3.67813C6.66386 3.57735 6.76699 3.425 6.8748 3.27032C6.93574 3.18125 6.99902 3.09219 7.05761 3.00782C7.18886 2.82266 7.3037 2.66797 7.39511 2.56016C7.86386 3.04766 8.35605 3.80235 8.74042 4.63203C9.15527 5.52735 9.41074 6.43672 9.41074 7.12344C9.41074 9.5375 7.56386 11.375 5.28574 11.375C2.98183 11.375 1.16074 9.5375 1.16074 7.12344C1.16074 6.24453 1.54042 5.19219 2.12871 4.16563V4.16328ZM4.71152 0.771878C4.33183 0.41094 3.73886 0.408597 3.35917 0.769534C2.60917 1.475 1.78886 2.49453 1.1537 3.60547C0.520892 4.70703 0.0357361 5.96094 0.0357361 7.12344C0.0357361 10.1305 2.33495 12.5 5.28574 12.5C8.20605 12.5 10.5357 10.1352 10.5357 7.12344C10.5357 6.20703 10.21 5.12657 9.75995 4.1586C9.30995 3.18594 8.70292 2.25547 8.0748 1.64844C7.69277 1.27813 7.09042 1.27813 6.70839 1.6461C6.52324 1.82422 6.30995 2.11485 6.13886 2.35625L6.11308 2.39375C5.6748 1.83594 5.15449 1.19141 4.71152 0.771878ZM5.32558 10.25C5.91855 10.25 6.44355 10.0859 6.93808 9.75782C7.9248 9.06875 8.18964 7.69063 7.59667 6.60782C7.49121 6.39688 7.22167 6.38282 7.06933 6.56094L6.4787 7.24766C6.32402 7.42578 6.04511 7.4211 5.8998 7.23594C5.51308 6.74375 4.82167 5.86485 4.42792 5.36563C4.28027 5.17813 3.99902 5.17578 3.84902 5.36328C3.05683 6.35938 2.65839 6.9875 2.65839 7.69297C2.66074 9.29844 3.84667 10.25 5.32558 10.25Z"
          fill="#FB4803"
        />
      </svg>
    </>
  );
};
