import {
  DepartmentDetails,
  DepartmentTreeEntity,
  OrganizationDetails,
  Qualification,
  QualificationSettings,
  Specialty,
} from '@libs/models/settings';
import { request } from '../../request';

export async function saveOrgQualifications(setting: QualificationSettings): Promise<void> {
  const response = await request.put('/v2/settings/qualifications', {
    nurses: setting.direct,
    other: setting.other,
  });

  // const fake = async () =>
  //   new Promise<void>((resolve) => {
  //     setTimeout(() => {
  //       resolve();
  //     }, 1000);
  //   });

  // return await fake();
}

export async function getOrgQualifications(id: string): Promise<QualificationSettings> {
  const response = await request.get<{ nurses: Qualification[]; other: Qualification[] }>(
    '/v2/settings/qualifications',
  );

  return {
    direct: response.data.nurses,
    other: response.data.other,
  };

  // const mock = {
  //   direct: [
  //     {
  //       id: 'test_qual_1',
  //       category: 'nursing_and_patient_care',
  //       name: 'Certified Nursing Assistant',
  //       abbreviation: 'CNA',
  //     },
  //     {
  //       id: 'test_qual_2',
  //       category: 'nursing_and_patient_care',
  //       name: 'Licensed Practical Nurse',
  //       abbreviation: 'LPN',
  //     },
  //     {
  //       id: 'test_qual_3',
  //       category: 'nursing_and_patient_care',
  //       name: 'Licensed Vocational Nurse',
  //       abbreviation: 'LVN',
  //     },
  //     {
  //       id: 'test_qual_4',
  //       category: 'nursing_and_patient_care',
  //       name: 'Registered Nurse',
  //       abbreviation: 'RN',
  //     },
  //     {
  //       id: 'test_qual_5',
  //       category: 'nursing_and_patient_care',
  //       name: 'Nurse Practitioner',
  //       abbreviation: 'NP',
  //     },
  //     {
  //       id: 'test_qual_6',
  //       category: 'nursing_and_patient_care',
  //       name: 'Clinical Nurse Specialist',
  //       abbreviation: 'CNS',
  //     },
  //     {
  //       id: 'test_qual_7',
  //       category: 'nursing_and_patient_care',
  //       name: 'Certified Nurse Midwife',
  //       abbreviation: 'CNM',
  //     },
  //   ],
  //   other: [
  //     {
  //       id: 'test_qual_22',
  //       category: 'custom_other',
  //       name: 'Test7',
  //       abbreviation: 'T7',
  //     },
  //   ],
  // };

  // const fake = async () =>
  //   new Promise<QualificationSettings>((resolve) => {
  //     setTimeout(() => {
  //       resolve(mock);
  //     }, 1000);
  //   });

  // return await fake();
}

export async function getDirectQualifications(): Promise<Qualification[]> {
  const response = await request.get<Qualification[]>('/v2/nurses/qualifications');

  return response.data;

  // const mock: Qualification[] = [
  //   {
  //     id: 'test_qual_1',
  //     category: 'nursing_and_patient_care',
  //     name: 'Certified Nursing Assistant',
  //     abbreviation: 'CNA',
  //   },
  //   {
  //     id: 'test_qual_2',
  //     category: 'nursing_and_patient_care',
  //     name: 'Licensed Practical Nurse',
  //     abbreviation: 'LPN',
  //   },
  //   {
  //     id: 'test_qual_3',
  //     category: 'nursing_and_patient_care',
  //     name: 'Licensed Vocational Nurse',
  //     abbreviation: 'LVN',
  //   },
  //   {
  //     id: 'test_qual_4',
  //     category: 'nursing_and_patient_care',
  //     name: 'Registered Nurse',
  //     abbreviation: 'RN',
  //   },
  //   {
  //     id: 'test_qual_5',
  //     category: 'nursing_and_patient_care',
  //     name: 'Nurse Practitioner',
  //     abbreviation: 'NP',
  //   },
  //   {
  //     id: 'test_qual_6',
  //     category: 'nursing_and_patient_care',
  //     name: 'Clinical Nurse Specialist',
  //     abbreviation: 'CNS',
  //   },
  //   {
  //     id: 'test_qual_7',
  //     category: 'nursing_and_patient_care',
  //     name: 'Certified Nurse Midwife',
  //     abbreviation: 'CNM',
  //   },
  //   {
  //     id: 'test_qual_8',
  //     category: 'nursing_and_patient_care',
  //     name: 'Certified Registered Nurse Anesthetist',
  //     abbreviation: 'CRNA',
  //   },
  //   {
  //     id: 'test_qual_9',
  //     category: 'nursing_and_patient_care',
  //     name: 'Advanced Practice Registered Nurse',
  //     abbreviation: 'APRN',
  //   },
  //   {
  //     id: 'test_qual_10',
  //     category: 'nursing_and_patient_care',
  //     name: 'Public Health Nurse',
  //     abbreviation: 'PHN',
  //   },
  //   {
  //     id: 'test_qual_11',
  //     category: 'nursing_and_patient_care',
  //     name: 'Travel Nurse',
  //     abbreviation: 'TravelNurse',
  //   },
  //   {
  //     id: 'test_qual_12',
  //     category: 'nursing_and_patient_care',
  //     name: 'Geriatric Nurse',
  //     abbreviation: 'GeriatricNurse',
  //   },
  //   {
  //     id: 'test_qual_13',
  //     category: 'nursing_and_patient_care',
  //     name: 'Pediatric Nurse',
  //     abbreviation: 'PediatricNurse',
  //   },
  //   {
  //     id: 'test_qual_14',
  //     category: 'nursing_and_patient_care',
  //     name: 'Emergency Room Nurse',
  //     abbreviation: 'ER',
  //   },
  //   {
  //     id: 'test_qual_15',
  //     category: 'nursing_and_patient_care',
  //     name: 'Operating Room Nurse',
  //     abbreviation: 'OR',
  //   },
  //   {
  //     id: 'test_qual_16',
  //     category: 'nursing_and_patient_care',
  //     name: 'Critical Care Nurse',
  //     abbreviation: 'CriticalCare',
  //   },
  //   {
  //     id: 'test_qual_17',
  //     category: 'nursing_and_patient_care',
  //     name: 'Flight Nurse',
  //     abbreviation: 'FlightNurse',
  //   },
  //   {
  //     id: 'test_qual_18',
  //     category: 'nursing_and_patient_care',
  //     name: 'School Nurse',
  //     abbreviation: 'SchoolNurse',
  //   },
  //   {
  //     id: 'test_qual_19',
  //     category: 'nursing_and_patient_care',
  //     name: 'Nurse Educator',
  //     abbreviation: 'NurseEd',
  //   },
  //   {
  //     id: 'test_qual_20',
  //     category: 'nursing_and_patient_care',
  //     name: 'Nurse Administrator',
  //     abbreviation: 'NurseAdmin',
  //   },
  // ];

  // const fake = async () =>
  //   new Promise<Qualification[]>((resolve) => {
  //     setTimeout(() => {
  //       resolve(mock);
  //     }, 1000);
  //   });

  // return await fake();
}
