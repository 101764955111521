import { create } from 'zustand';
import { getOrganizationDetails, saveOrganizationDetails, getDirectQualifications } from '@libs/api/settings';
import { OrganizationDetails, Qualification } from '@libs/models/settings';

type InitialState = {
  loading: boolean;
  data?: Qualification[];
  save: (settings: Omit<OrganizationDetails, 'id'>) => Promise<void>;
  load: () => void;
};

export const useDirectQualificationsStore = create<InitialState>()((set) => ({
  loading: false,
  save: async (settings) => {},
  load: async () => {
    set({ loading: true });
    try {
      // TODO: use real ID
      const list = await getDirectQualifications();
      set({ loading: false, data: list });
    } finally {
      set({ loading: false });
    }
  },
}));
