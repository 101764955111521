import { DateHelper, ResourceModel } from '@bryntum/schedulerpro';
import { Qualification } from '@libs/models/settings';

// Custom Doctor resource model, based on ResourceModel with additional fields
export class Doctor extends ResourceModel {
  declare department: string;
  declare avatar: string;
  declare qualifications: Qualification[];
  declare rating: number;

  static fields = ['department', 'roleIconCls'];
}
