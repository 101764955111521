import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';
import { Box } from '../../atoms/layout/box';

type DelayedLinearProgressProps = {
  show?: boolean;
};
export function DelayedLinearProgress({ show = true }: DelayedLinearProgressProps) {
  // @TODO - move this value into theme
  const transitionDelayInMs = `600ms`;
  return (
    <Box height={0}>
      <Fade
        in={show}
        style={{
          transitionDelay: show ? transitionDelayInMs : '0ms',
        }}
      >
        <LinearProgress />
      </Fade>
    </Box>
  );
}
