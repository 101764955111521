import { create } from 'zustand';
import { getOrgQualifications, saveOrgQualifications } from '@libs/api/settings';
import { QualificationSettings } from '@libs/models/settings';

type InitialState = {
  loading: boolean;
  editDirectQualDrawerOpen: boolean;
  editOtherQualDrawerOpen: boolean;
  orgQualifications?: QualificationSettings;
  save: (settings: QualificationSettings) => Promise<void>;
  load: () => void;
  setDirectDrawerOpen: (isOpen: boolean) => void;
  setOtherDrawerOpen: (isOpen: boolean) => void;
};

export const useOrgQualificationsStore = create<InitialState>()((set) => ({
  loading: false,
  editDirectQualDrawerOpen: false,
  editOtherQualDrawerOpen: false,
  save: async (settings) => {
    if (!settings) return;
    try {
      await saveOrgQualifications(settings);
    } finally {
      set({ orgQualifications: settings });
    }
  },
  load: async () => {
    set({ loading: true });
    try {
      // TODO: use real ID
      const setting = await getOrgQualifications('e0426c93-4c30-479a-9c42-b0864f461b60');
      set({ loading: false, orgQualifications: setting });
    } finally {
      set({ loading: false });
    }
  },
  setDirectDrawerOpen: (isOpen) => {
    set({ editOtherQualDrawerOpen: false });
    set({ editDirectQualDrawerOpen: !!isOpen });
  },
  setOtherDrawerOpen: (isOpen) => {
    set({ editDirectQualDrawerOpen: false });
    set({ editOtherQualDrawerOpen: !!isOpen });
  },
}));
